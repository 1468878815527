import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import { YearsEndPostingModel } from '@shared/data-access/models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class YearsEndPostingApiService {
  static ROOT_POINT = 'finance/asset-categories';

  static ROOT_POINT2 = 'finance/account-period';

  constructor(private apiService: ApiService) { }

  get(itemId: number, query = {}): Observable<YearsEndPostingModel> {
    return this.apiService.get(YearsEndPostingApiService.ROOT_POINT2 + '/' + itemId).pipe(map(res => YearsEndPostingModel.fromJson(res) as YearsEndPostingModel));
  }

  search(query = {}, option?: IApiOption): Observable<PaginationAdapter<YearsEndPostingModel>> {
    return this.apiService.get(YearsEndPostingApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(YearsEndPostingModel, data)));
  }
}
