import { Injectable, InjectionToken } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, ReplaySubject, map, of, throwError } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
export const EXCELJS_MEDIA_WATHCER_HI = new InjectionToken<boolean>('EXCELJS_MEDIA_WATHCER_HI');

@Injectable()
export class ExceljsMediaWatcherResolver implements Resolve<string[]> {

  private _onMediaChange: ReplaySubject<{ matchingAliases: string[]; matchingQueries: any }> = new ReplaySubject<{ matchingAliases: string[]; matchingQueries: any }>(
    1
  );
  private breakpoints = {
    lg: '(min-width: 1280px)',
    md: '(min-width: 960px)',
    print: 'print',
    sm: '(min-width:600px)',
    xl: '(min-width:1440px)',
  };
  constructor(
    private _breakpointObserver: BreakpointObserver
  ) {

    this._breakpointObserver
      .observe(Object.values(this.breakpoints))
      .pipe(
        map(state => {
          // Prepare the observable values and set their defaults
          const matchingAliases: string[] = [];
          const matchingQueries: any = {};

          // Get the matching breakpoints and use them to fill the subject
          const matchingBreakpoints = Object.entries(state.breakpoints).filter(([query, matches]) => matches) ?? [];
          for (const [query] of matchingBreakpoints) {
            // Find the alias of the matching query
            const matchingAlias = (Object.entries(this.breakpoints).find(([alias, q]) => q === query) as string[])[0];

            // Add the matching query to the observable values
            if (matchingAlias) {
              matchingAliases.push(matchingAlias);
              matchingQueries[matchingAlias] = query;
            }
          }

          // Execute the observable
          this._onMediaChange.next({
            matchingAliases,
            matchingQueries,
          });
        })
      )
      .subscribe();
  }
  get onMediaChange$(): Observable<{ matchingAliases: string[]; matchingQueries: any }> {
    return this._onMediaChange.asObservable();
  }
  resolve() {
    return this.onMediaChange$.pipe(
      map(({ matchingAliases }) => {
        return matchingAliases;
      })
    );

  }
}
