import { AwardsReportAction, BankVerificationInfoAction } from '../actions';

export * as PhoneNumberReducer from './phone-number.reducers';
export * as SalespersonAdditionalInfoReducer from './salesperson-additional-information.reducers';
export * as EducationRecordReducer from './education-record.reducers';
export * as EmploymentHistoryReducer from './employment-history.reducers';
export * as CommSalespersonSchemeReducer from './comm-salesperson-scheme.reducers';
export * as CommLeadershipSchemeReducer from './comm-leadership-scheme.reducers';
export * as ProfitSharingReducer from './profit-sharing.reducers';
export * as ProjectSetupReducer from './project-setup.reducers';
export * as DeveloperReducer from './developer.reducers';
export * as CommissionMatrixReducer from './comm-matrix.reducers';
export * as ProjectCommissionMatrixReducer from './project-comm-matrix.reducers';
export * as OverridingDistributionReducer from './overriding-distribution.reducers';
export * as BonusDistributionReducer from './bonus-distribution.reducers';
export * as AppointmentTypeReducer from './appointment-type.reducers';
export * as ProjectConsultantReducer from './project-consultant.reducers';
export * as ProjectTransactionReducer from './project-transaction.reducers';
export * as CareerProgressionReducer from './career-progression.reducer';
export * as ManagementCommissionReducer from './management-commission.reducer';
export * as BlankFormReducer from './blank-form.reducer';
export * as BatchingApprovalReducer from './batching-approval.reducers';
export * as ChartOfAccountsReducer from './chart-of-accounts.reducers';
export * as ProfitCenterReducer from './profit-center.reducers';
export * as JournalReducer from './journal.reducer';
export * as ContactCustomerOrSupplierReducer from './contact-customer-or-supplier.reducer';
export * as UserAccountReducer from './user-account.reducers';
export * as UserGroupReducer from './user-group.reducers';
export * as UserRoleReducer from './user-role.reducers';
export * as ContactReducer from './contact.reducers';
export * as ResaleTransactionReducer from './resale-transaction.reducer';
export * as ProjectCommissionMatrixHIReducer from './project-comm-matrix-hi.reducers';
export * as RecruitmentReducer from './recruitment.reducers';
export * as ResaleAdjustmentReducer from './resale-adjustment.reducers';
export * as BatchSearchEngineReducer from './batch-search-engine.reducers';

export * as ProjectReportReducer from './project-report.reducers';
export * as ResaleReportReducer from './resale-report.reducers';
export * as CustomerReportReducer from './customer-report.reducers';
export * as SupplierReportReducer from './supplier-report.reducers';
export * as BankAccountReducer from './bank-account.reducers';

export * as AssetCategoryReducer from './asset-category.reducers';
export * as YearsEndPostingReducer from './years-end-posting.reducers';
export * as SwitchCompanyLoaderReducer from './switch-company-loader.reducers';
export * as PersonalVerificationInfoReducer from './personal-verification-info.reducers';
export * as PersonalParticularReducer from './personal-particular.reducers';
export * as GceAmountReducer from './gce-amount.reducers';
export * as PromotionApprovalReducer from './promotion-approval.reducers';

export * as AssetRegisterReducer from './asset-register.reducers';

export * as PaymentReducer from './payment.reducers';

export * as ReceiptReducer from './receipt.reducers';

export * as ChequeReducer from './cheque.reducers';

export * as CurrencyRateReducer from './currency-rate.reducers';

export * as CommMatrixTypeReducer from './comm-matrix-type.reducers';

export * as TransactionTypeReducer from './transaction-type.reducers';

export * as KeyAppointmentListReducer from './key-appointment-list.reducers';

export * as TaxInvoiceReducer from './tax-invoice.reducers';
export * as CreditNoteReducer from './credit-note.reducers';
export * as Ir8aAmountReducer from './ir8a-amount.reducers';
export * as DebitNoteReducer from './debit-note.reducers';

export * as AssetDepreciationReducer from './asset-depreciation.reducers';

export * as DirectDebitPaymentReducer from './direct-debit-payment.reducers';

export * as PaymentGeneratorReducer from './payment-generator.reducers';

export * as FinanceReportsReducer from './finance-reports.reducers';
export * as PaymentReversalReducer from './payment-reversal.reducers';

export * as AgentReportReducer from './agent-report.reducers';

export * as TeamOrgChartReducer from './team-org-chart.reducers';

export * as InvoiceWriteoffReducer from './invoice-writeoff.reducers';

export * as SupplierReducer from './supplier.reducers';
export * as SupplierInvoiceReducer from './supplier-invoice.reducers';
export * as SupplierCreditNoteReducer from './supplier-credit-note.reducers';

export * as CustomerReducer from './customer.reducers';

export * as JournalEntryReducer from './journal-entry.reducers';

export * as LookupTableReducer from './lookup-table.reducers';

export * as AccountingPeriodsReducer from './accounting-periods.reducers';

export * as AssetDisposalReducer from './asset-disposal.reducers';
export * as AwardsReportReducer from './awards-report.reducer';
export * as BankVerificationInfoReducer from './bank-verification-info.reducers';
export * as BlankFormBatchSearchEngineReducer from './blank-form-batch-search-engine.reducers'