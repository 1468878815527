import { BaseModel, Default } from '@red/data-access';
import { Expose, Transform, Type } from 'class-transformer';
import { SUPPLIER_CREDIT_NOTE_PAID_STATUS } from '../data';
import { ESupplierCreditNoteDetailStatus, ESupplierCreditNoteStatus } from '../enums';
import { EBalanceDocumentPostingType, ECustomerOrSupplier, EInvoiceCreateMode } from '../enums/tax-invoice-enhanced.enum';
import {
  IStatusDescription,
  ISupplierCreditNote,
  ISupplierCreditNotePosting,
  ISupplierCreditNoteRecord,
  ISupplierCreditNoteSummary,
  ISupplierInvoice,
  ITaxInvoice,
} from '../interfaces';
import { ContactAddressModel } from './contact-address.model';
import { ContactCustomerAndSupplierModel } from './contact-customer-and-supplier.model';
import { ContactPersonModel } from './contact-person.model';
import { CurrencyRateModel } from './currency-rate.model';
import { GstCategoryLookupModel } from './gst-category.model';
import { LedgerAccountModel } from './ledger-account.model';
import { PersonalParticularModel } from './personal-particular.model';
import { ProfitCentresModel } from './profit-centres.model';
import { TemplateModel } from './template.model';
import { UpdatedByModel } from './updated-by.model';

export class SupplierCreditNoteRecordModel extends BaseModel implements ISupplierCreditNoteRecord {
  @Expose()
  id?: number;

  @Expose()
  itemCode?: string;

  @Expose()
  description!: string;

  @Expose()
  amount!: number;

  @Expose()
  balanceDue!: number;

  @Expose()
  paidAmount!: number;

  @Expose()
  @Transform(({ obj }) => {
    console.log('obj', obj)
    if (obj.id && obj?.gstInclusive && (obj?.balanceDue === 0 || !obj?.balanceDue)) {
      console.log('1',)
      return obj?.unitPrice
    }
    else if (!obj.gstInclusive && obj.gstPercent > 0 && (obj?.balanceDue || obj?.balanceDue > 0)) {
      // const amountGSTExclusive = obj?.balanceDue / ((100 + obj.gstPercent) / 100)
      console.log('2')
      const amountGSTExclusive = obj?.balanceDue * 100 / (100 + obj.gstPercent)
      return amountGSTExclusive
    }
    else if (obj?.gstInclusive && obj.gstPercent > 0 && (!obj?.balanceDue)) {
      console.log('3')
      return obj.unitPrice
    }
    else if (obj?.balanceDue === 0 || !obj?.balanceDue) {
      console.log('4')
      return obj?.amount
    }
    return obj?.balanceDue
  })
  amountTransform!: number;

  @Expose()
  quantity!: number;

  @Expose()
  unitPrice!: number;

  @Expose()
  discount!: number;

  @Expose()
  gstCategory!: string;

  @Expose()
  @Transform(({ obj }) => {
    if (obj.gstCategoryLookup) {
      return obj.gstCategoryLookup
    }
    return obj.gstCategory ? { gst_code: obj.gstCategory } : null
  })
  @Type(() => GstCategoryLookupModel)
  gstCategoryLookup?: GstCategoryLookupModel;

  @Expose()
  @Transform(({ obj }) => {
    if (obj.gstCharged < 0) {
      return 0
    }
    return obj?.supplierInvoice?.gstPercent ?? obj.gstPercent ?? Number(obj.gstCategoryLookup?.gst_rate)
  })
  @Type(() => Number)
  gstPercent?: number;

  @Expose()
  @Transform(({ obj }) => obj.gstCharged ?? Number(obj.gstCategoryLookup?.gst_charged))
  @Type(() => Number)
  gstCharged?: number;

  @Expose()
  gstInclusive!: boolean;

  @Expose()
  gstValue?: number;

  // @Expose()
  // gstPercent?: number;

  //   @Expose()
  //   bankReferralFee?: boolean;

  @Expose()
  uom?: string;

  @Expose()
  remarks?: string;

  @Expose()
  @Type(() => LedgerAccountModel)
  account!: LedgerAccountModel;

  @Expose()
  accountId!: number;

  @Expose()
  @Transform((object) => {
    if (object.obj?.account) {
      return object.obj?.account?.code
    }
    return object.obj?.accountCode
  })
  accountCode!: string;

  @Expose()
  @Type(() => ProfitCentresModel)
  profitCenter!: ProfitCentresModel;

  @Expose()
  profitCenterId?: number;

  @Expose()
  @Type(() => Number)
  supplierInvoiceDetailId?: number;

  @Expose()
  @Type(() => Number)
  supplierInvoiceId?: number;

  @Expose()
  supplierInvoice?: ISupplierInvoice;

  @Expose()
  @Type(() => Number)
  total?: number;

  @Expose()
  taxInvoice?: ITaxInvoice;

  // @Expose()
  // @Type(() => Number)
  // creditNoteId?: number;

  // @Expose()
  // SupplierCreditNote!: SupplierCreditNoteModel;

  // @Expose()
  // SupplierCreditNoteId!: number;

  //   @Expose()
  //   agentId?: number;

  //   @Expose()
  //   @Type(() => PersonalParticularModel)
  //   agent?: PersonalParticularModel;

  @Expose()
  index?: number;
}

export class SupplierCreditNotePostingModel extends BaseModel implements ISupplierCreditNotePosting {
  @Expose()
  @Type(() => LedgerAccountModel)
  account!: LedgerAccountModel;

  @Expose()
  accountId!: number;

  @Expose()
  @Transform((object) => {
    if (object.obj?.account) {
      return object.obj?.account?.code
    }
    return object.obj?.accountCode
  })
  accountCode!: string;

  @Expose()
  @Type(() => ProfitCentresModel)
  profitCenter?: ProfitCentresModel;

  @Expose()
  profitCenterId?: number;

  @Expose()
  description?: string;

  @Expose()
  debit!: number;

  @Expose()
  credit!: number;

  @Expose()
  amount!: number;

  @Expose()
  currency?: string;

  // @Expose()
  // creditNoteId!: number;

  // @Expose()
  // creditNoteDetailId?: number;

  // @Expose()
  // SupplierCreditNoteDetail?: ISupplierCreditNoteDetailEnhanced;

  @Expose()
  type?: EBalanceDocumentPostingType;
}

export class SupplierCreditNoteModel extends BaseModel implements ISupplierCreditNote {
  // General
  @Expose()
  @Type(() => ContactCustomerAndSupplierModel)
  contact!: ContactCustomerAndSupplierModel;

  @Expose()
  @Type(() => ContactAddressModel)
  billingAddress!: ContactAddressModel;

  @Expose()
  contactId?: number;

  @Expose()
  contactAddressId!: number;

  @Expose()
  creditNoteNumber?: string;

  @Expose()
  creditNoteDate!: string;

  @Expose()
  @Type(() => ContactPersonModel)
  contactPerson?: ContactPersonModel;

  @Expose()
  contactPersonId?: number;

  // @Expose()
  // customerName?: string;

  @Expose()
  @Default([])
  supplierInvoices?: ISupplierInvoice[];

  @Expose()
  taxInvoiceId?: number;

  @Expose()
  taxInvoice?: ITaxInvoice;

  // Summary
  @Expose()
  gstEdited!: boolean;

  @Expose()
  amount!: number;

  @Expose()
  discount!: number;

  @Expose()
  subTotal!: number;

  @Expose()
  gstPercent!: number;

  @Expose()
  gstValue!: number;

  @Expose()
  total!: number;

  @Expose()
  gst?: number;

  @Expose()
  balanceDue?: number;

  @Expose()
  inClosedPeriod?: boolean;

  // Other Details
  @Expose()
  currencyId!: number;

  @Expose()
  currencyCode?: string;

  @Expose()
  @Type(() => CurrencyRateModel)
  currency!: CurrencyRateModel;

  @Expose()
  billingAddressCustom?: string;

  @Expose()
  contactPersonCustom?: string;

  @Expose()
  reference?: string;

  @Expose()
  creditTerm?: number;

  @Expose()
  paymentTerm?: number;

  @Expose()
  salePersonId?: number;

  @Expose()
  @Type(() => PersonalParticularModel)
  salePerson?: PersonalParticularModel;

  @Expose()
  remarks?: string;

  @Expose()
  templateId?: number;

  @Expose()
  @Type(() => TemplateModel)
  template?: TemplateModel;

  // General
  @Expose()
  id!: number;

  @Expose()
  businessUnitId!: number;

  @Expose()
  type!: ECustomerOrSupplier;

  @Expose()
  status?: ESupplierCreditNoteStatus;

  @Expose()
  @Transform(({ value }) => {
    if (!value) return undefined;
    if (typeof value === 'string' && !isNaN(+value)) return undefined;

    return SUPPLIER_CREDIT_NOTE_PAID_STATUS[value as ESupplierCreditNoteDetailStatus];
  })
  paidStatus?: IStatusDescription;

  @Expose()
  createdMode?: EInvoiceCreateMode;

  @Expose()
  projectId?: number;

  @Expose()
  paidAmount?: number;

  @Expose()
  isWithHold?: boolean;

  @Expose()
  adjustment?: boolean;

  @Expose()
  miscAdjustment?: boolean;

  @Expose()
  parentInvoice?: ITaxInvoice;

  @Expose()
  parentInvoiceId?: number;

  @Expose()
  @Transform(({ obj }) => {
    const isModeInvalid = obj.createdMode === EInvoiceCreateMode.MANUAL;
    const hasParentInvoice = !!obj.parentInvoiceId && !!obj.parentInvoice;
    const hasReferenceModified = obj.reference !== obj.parentInvoice?.invoiceNumber;

    return !isModeInvalid && hasParentInvoice && !hasReferenceModified;
  })
  isReferenceLinked?: boolean;

  @Expose()
  isPaid?: boolean;

  @Expose()
  @Transform(({ obj }) => {
    const isPaidStatusValid = [ESupplierCreditNoteDetailStatus.FullyPaid].includes(obj.paidStatus);

    const isPaidAmountValid = obj.paidAmount === obj.amount || obj.balanceDue === 0;

    return isPaidAmountValid || isPaidStatusValid;
  })
  isFullyPaid?: boolean;

  // Base
  @Expose()
  createdAt?: string;

  @Expose()
  updatedAt?: string;

  @Expose()
  createdBy?: string;

  @Expose()
  updatedBy?: UpdatedByModel;

  @Expose()
  @Transform(({ obj }) => {
    const isStatusInvalid = false;
    const isPaidStatusInvalid = [
      ESupplierCreditNoteDetailStatus.PaymentInProgress,
      ESupplierCreditNoteDetailStatus.Withhold,
      ESupplierCreditNoteDetailStatus.PartPaid,
      ESupplierCreditNoteDetailStatus.Pending,
      // ESupplierCreditNoteDetailStatus.FullyPaid,
    ].includes(obj.paidStatus);
    const isPaidAmountInvalid = obj.isPaid;
    // const isPaidAmountInvalid = obj.isPaid || obj.paidAmount === obj.amount || obj.balanceDue === 0;
    return !isStatusInvalid && !isPaidStatusInvalid && !isPaidAmountInvalid && !obj.inClosedPeriod;
  })
  canEdit!: boolean;

  @Expose()
  @Transform(({ obj }) => {
    const isStatusInvalid = false;
    const isPaidStatusInvalid = [
      ESupplierCreditNoteDetailStatus.PaymentInProgress,
      ESupplierCreditNoteDetailStatus.Withhold,
      ESupplierCreditNoteDetailStatus.PartPaid,
      ESupplierCreditNoteDetailStatus.Pending,
      // ESupplierCreditNoteDetailStatus.FullyPaid,
    ].includes(obj.paidStatus);
    const isPaidAmountInvalid = obj.isPaid;
    // const isPaidAmountInvalid = obj.isPaid || obj.paidAmount === obj.amount || obj.balanceDue === 0;
    return !isStatusInvalid && !isPaidStatusInvalid && !isPaidAmountInvalid && !obj.inClosedPeriod;
  })
  canDelete!: boolean;

  @Expose()
  @Transform(({ obj }) => {
    const isStatusInvalid = false;
    const isPaidStatusInvalid = [
      ESupplierCreditNoteDetailStatus.PaymentInProgress,
      ESupplierCreditNoteDetailStatus.Withhold,
      ESupplierCreditNoteDetailStatus.PartPaid,
      ESupplierCreditNoteDetailStatus.Pending,
      // ESupplierCreditNoteDetailStatus.FullyPaid,
    ].includes(obj.paidStatus);
    const isPaidAmountInvalid = obj.isPaid;
    // const isPaidAmountInvalid = obj.isPaid || obj.paidAmount === obj.amount || obj.balanceDue === 0;
    return !isStatusInvalid && !isPaidStatusInvalid && !isPaidAmountInvalid;
  })
  isLock!: boolean;

  // @Expose()
  // invoiceType?: ECustomerOrSupplier;

  // @Expose()
  // printFormat?: string;

  // @Expose()
  // invoiceDueDate?: string;

  // @Expose()
  // ecbInvoiceDate?: string;

  // @Expose()
  // paidStatus?: string;

  // @Expose()
  // balanceDue?: number;

  // @Expose()
  // parentInvoiceId?: number;

  // @Expose()
  // batchComment?: string;

  // @Expose()
  // batchName?: string;

  // @Expose()
  // batchCode?: string;

  // @Expose()
  // attention?: string;

  // @Expose()
  // supplierInvoiceType?: ESupplierInvoice;

  // @Expose()
  // adjustment?: boolean;

  // @Expose()
  // fileAttachedName?: string;

  // @Expose()
  // fileAttachedViewName?: string;

  // @Expose()
  // balanceDocumentCodeType?: EBalanceDocumentCode;
}

export class SupplierCreditNoteSummaryModel extends BaseModel implements ISupplierCreditNoteSummary {
  @Expose()
  amount!: number;

  @Expose()
  discount!: number;

  @Expose()
  subTotal!: number;

  @Expose()
  gstPercent!: number;

  @Expose()
  gstValue!: number;

  @Expose()
  total!: number;

  @Expose()
  gstEdited!: boolean;
}
