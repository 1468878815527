import { createFeature, createReducer, on } from '@ngrx/store';
import { BatchingApprovalModel } from '@shared/data-access/models';
import { BlankFormBatchSearchEngineAction } from '../actions';

export const featureName = 'blankFormBatchSearchEngine';

export interface BlankFormBatchSearchEngineState {
  item: BatchingApprovalModel | null;
}

export const initialState: BlankFormBatchSearchEngineState = {
  item: null,
};

export const blankFormBatchSearchEngineFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(BlankFormBatchSearchEngineAction.setItemDetail, (state, { item }) => ({
      ...state,
      item,
    }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = blankFormBatchSearchEngineFeature;
