import { BaseModel } from '@red/data-access';
import { Expose } from 'class-transformer';

export class YearsEndPostingCreateDto extends BaseModel {
  @Expose()
  code!: string;

  @Expose()
  name!: string;

  @Expose()
  assetAccountId!: number;

  @Expose()
  assetDepreciationAccountId!: number;

  @Expose()
  assetAccumulatedAccountId!: number;

  @Expose()
  assetDisposalAccountId!: number;

  @Expose()
  yearPeriod!: number;

  @Expose()
  monthPeriod!: number;
}

// export class AssetCategoryUpdateDto extends BaseModel {
//   @Expose()
//   name!: string;
// }
