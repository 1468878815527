import { ECreditNoteStatus } from '../enums';
import { EBalanceDocumentPostingType, ECustomerOrSupplier, EInvoiceCreateMode } from '../enums/tax-invoice-enhanced.enum';
import { CurrencyRateModel } from '../models';
import { IContactAddress } from './contact-address.interface';
import { IContactCustomerAndSupplier } from './contact-customer-and-supplier.interface';
import { IContactPerson } from './contact-person.interface';
import { ILedgerAccount } from './ledger-account.interface';
import { IPersonalParticular } from './personal-particular.interface';
import { IProfitCentres } from './profit-centres.interface';
import { IStatusDescription } from './status-description.interface';
import { ITaxInvoice } from './tax-invoice-enhanced.interface';
import { ITemplates } from './template.interface';
import { IUpdatedBy } from './updated-by.interface';

export interface ICreditNoteRecord {
  id?: number;

  itemCode?: string;
  description: string;

  amount?: number;
  quantity: number;
  unitPrice: number;
  discount: number;

  gstCategory: string;
  gstInclusive: boolean;
  gstValue?: number;

  gstPercent?: number;
  gstCharged?: number;
  taxPercent?: number;

  // bankReferralFee?: boolean;

  uom?: string;
  remarks?: string;

  account: ILedgerAccount;
  accountId: number;
  accountCode: string;

  profitCenter: IProfitCentres;
  profitCenterId?: number;

  taxInvoiceDetailId?: number;
  taxInvoiceId?: number;
  taxInvoice?: ITaxInvoice;
  createdMode?: EInvoiceCreateMode;

  creditNoteId?: number;

  balanceDue?: number;

  // agentId?: number;
  // agent?: IPersonalParticular;
}

export interface ICreditNotePosting {
  account?: ILedgerAccount;
  accountId: number;
  accountCode: string;

  profitCenter?: IProfitCentres;
  profitCenterId?: number;

  description?: string;

  debit: number;
  credit: number;
  amount: number;

  currency?: string;

  creditNoteId: number;
  creditNoteDetailId?: number;
  // taxInvoiceDetail?: ITaxInvoiceDetailEnhanced;

  type?: EBalanceDocumentPostingType;
}


export interface ICreditNotePostingAccount {
  accountCode: string;
  accountId: number;
  accountName: string;
}

export interface ICreditNote {
  // General
  contact: IContactCustomerAndSupplier;
  billingAddress: IContactAddress;
  contactId?: number;
  contactAddressId: number;

  creditNoteNumber?: string;
  creditNoteDate: string;

  contactPerson?: IContactPerson;
  contactPersonId?: number;
  customerName?: string;

  taxInvoices?: ITaxInvoice[];

  // Summary
  gstEdited: boolean;
  amount: number;
  discount: number;
  subTotal: number;
  gstPercent: number;
  gstValue: number;
  total: number;
  gst?: number;
  balanceDue?: number;

  // Other Details
  currencyId: number;
  currency: CurrencyRateModel;

  billingAddressCustom?: string;
  contactPersonCustom?: string;

  reference?: string;

  creditTerm?: number;
  paymentTerm?: string;

  salePersonId?: number;
  salesperson?: IPersonalParticular;

  remarks?: string;
  creditNoteRemarks?: string;

  templateId?: number;
  template?: ITemplates;

  // General
  id: number;
  businessUnitId: number;
  isPaid?: boolean;
  type?: ECustomerOrSupplier.CUSTOMER;
  status?: ECreditNoteStatus;
  createdMode?: EInvoiceCreateMode;

  projectId?: number;
  paidAmount?: number;
  paidStatus?: IStatusDescription;
  isWithHold?: boolean;
  adjustment?: boolean;
  miscAdjustment?: boolean;
  inClosedPeriod?: boolean;

  // For Payments
  posting?: ICreditNotePostingAccount

  createAt?: string;
  updateAt?: string;
  createdBy?: string;
  updatedBy?: IUpdatedBy;
}

export interface ICreditNoteSummary {
  amount: number;
  discount: number;
  subTotal: number;
  gstPercent: number;
  gstValue: number;
  total: number;
  gstEdited: boolean;
}

export interface ICreditNoteCalculateSummary {
  details: Partial<ICreditNoteRecord>[];
  summary: ICreditNoteSummary;
}

export interface ICreditNoteInitData {
  contact: IContactCustomerAndSupplier;
  invoice: ITaxInvoice;
}

export interface ICreditNotePayment {
  amount: number;
  date: Date;
  id: number;
  referenceNo: string;
  type: string;
  // paymentContactType: ECustomerOrSupplier;
}

export interface ICreditNoteSupplierRefund {
  id?: number;
  creditNote?: Partial<ICreditNote>;
  total?: number;
  paidAmount?: number;
  balanceDue: number;
}
