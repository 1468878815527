import { Injectable } from '@angular/core';
import { ApiService, IApiOption } from '@red/api';
import { PaginationAdapter } from '@red/data-access';
import {
  PreviewOriginalTemplateDto,
  ResaleBillingDto,
  ResaleBillingUpdateDto,
  ResaleDocumentSetUpDto,
  ResaleTransactionCheckDuplicateDto,
  ResaleTransactionClonedDto,
  ResaleTransactionDto,
  ResaleTransactionPreviewDto,
  ResaleTransactionRemarkDto,
  ResaleTransactionUpdateDto,
  ResaleTransactionUpdatePaymentDto,
  ResaleTransactionUpdateRemarkDto,
  ResaleTransactionUpdateSpecialFieldsDto,
} from '@shared/data-access/dto';
import { EResaleStatus } from '@shared/data-access/enums';
import { IResaleTransaction } from '@shared/data-access/interfaces';
import { ResaleBillingModel, ResaleDocumentModel, ResaleSearchEngineModel, ResaleStatusHistoryModel, ResaleTransactionModel } from '@shared/data-access/models';
import { merge } from 'lodash-es';
import { TypedArray } from 'pdfjs-dist/types/src/display/api';
import { Observable, forkJoin, map, of, switchMap, takeLast } from 'rxjs';


export interface IResaleJobRes {
  jobId: string
}
export interface IResaleJobStatusRes {
  status: 'active' | 'completed' | 'failed'; // Job đã chạy hay chưa
  success: boolean // Job chạy có thành công hay không
  errorMessage?: string;
}
@Injectable({
  providedIn: 'root',
})
export class ResaleTransactionApiService {
  static TRANSACTION = 'transaction';
  static ROOT_POINT = 'transaction/resale-transactions';
  static BILLINGS_POINT = 'transaction/resale-billings';
  static DOCUMENTS_POINT = 'transaction/resale-documents';
  static DOCUMENTS_SETUP_POINT = 'transaction/resale-documents/setup';
  static SUBMIT_POINT = 'transaction/resale-transactions/:id/submit';
  static REWORK_POINT = 'transaction/resale-transactions/:id/rework';
  static CONFIRM_POINT = 'transaction/resale-transactions/:id/action';
  static COMPLETE_PC_DOC_POINT = 'transaction/resale-transactions/:id/verify-approve';
  static SUBMIT_MULTIPLE_POINT = 'transaction/resale-transactions/submit';
  static REWORK_MULTIPLE_POINT = 'transaction/resale-transactions/rework';
  static CONFIRM_MULTIPLE_POINT = 'transaction/resale-transactions/action';
  static WITHDRAW_MULTIPLE_POINT = 'transaction/resale-transactions/withdraw';
  static STATUS_HISTORIES_POINT = 'transaction/resale-status-histories';
  static SEARCH_ENGINE_POINT = 'transaction/engine/resales';
  static SEARCH_ENGINE_FINANCE_POINT = 'transaction/engine/finance/resales';
  static PREVIEW_POINT = 'transaction/resale-transactions/preview';
  static PC_DOCS_POINT = 'transaction/resale-transactions/pc-docs';
  static SEARCH_ENGINE_DOWNLOAD = 'transaction/engine/resales/download';
  static SEARCH_ENGINE_FINANCE_DOWNLOAD = 'transaction/engine/finance/resales/download';
  static SAVE_DRAFT_POINT = 'transaction/resale-transactions/save-draft';
  static UPDATE_SAVE_DRAFT_POINT = 'transaction/resale-transactions/:id/save-draft';
  static UPDATE_SAVE_SPECIAL_POINT = 'transaction/resale-transactions/:id/save-special';
  static SUBMISSION_ROOT_POINT = 'transaction/resale-transactions/submission';
  static VERIFICATION_ROOT_POINT = 'transaction/resale-transactions/verification';
  static INVOICE_INTERFACE_ROOT_POINT = 'transaction/resale-transactions/invoice-interface';
  static SUBMISSION_CONFIRM_MULTIPLE_POINT = 'transaction/resale-transactions/action/submission';
  static VERIFICATION_CONFIRM_MULTIPLE_POINT = 'transaction/resale-transactions/action/verification';
  static INVOICE_INTERFACE_CONFIRM_MULTIPLE_POINT = 'transaction/resale-transactions/action/invoice-interface';
  static SUBMISSION_REWORK_MULTIPLE_POINT = 'transaction/resale-transactions/rework/submission';
  static VERIFICATION_REWORK_MULTIPLE_POINT = 'transaction/resale-transactions/rework/verification';
  static INVOICE_INTERFACE_REWORK_MULTIPLE_POINT = 'transaction/resale-transactions/rework/invoice-interface';
  static UPDATE_PAYMENT_POINT = 'transaction/resale-transactions/:id/payment';
  static DOCUMENTS_SETUP_PAYMENT_POINT = 'transaction/resale-documents/setup-payment';

  static SEARCH_ENGINE_RESALE_JOB_DOWNLOAD = 'transaction/engine/resales/job/download';
  static SEARCH_ENGINE_JOB_STATUS = 'transaction/engine/resales/job/status';
  static SEARCH_ENGINE_JOB_DOWNLOAD = 'transaction/engine/job/download';


  constructor(private _apiService: ApiService) { }

  viewOriginal(data: PreviewOriginalTemplateDto): Observable<unknown> {
    return this._apiService.post(ResaleTransactionApiService.TRANSACTION + '/template-v2/preview/original', data, {
      pretreatmentResponse: false,
      requestOptions: { responseType: 'arraybuffer' },
    });
  }

  get(itemId: number, query = {}) {
    return this._apiService.get(ResaleTransactionApiService.ROOT_POINT + '/' + itemId, query).pipe(map(res => ResaleTransactionModel.fromJson(res)));
  }

  search(query = {}, option?: IApiOption) {
    return this._apiService.get(ResaleTransactionApiService.ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(ResaleTransactionModel, data)));
  }

  searchSubmission(query = {}, option?: IApiOption) {
    return this._apiService.get(ResaleTransactionApiService.SUBMISSION_ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(ResaleTransactionModel, data)));
  }

  searchVerification(query = {}, option?: IApiOption) {
    return this._apiService.get(ResaleTransactionApiService.VERIFICATION_ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(ResaleTransactionModel, data)));
  }

  searchInvoiceInterface(query = {}, option?: IApiOption) {
    return this._apiService.get(ResaleTransactionApiService.INVOICE_INTERFACE_ROOT_POINT, query, option).pipe(map(data => new PaginationAdapter(ResaleTransactionModel, data)));
  }

  create(data: ResaleTransactionDto | ResaleTransactionClonedDto) {
    return this._apiService.post(ResaleTransactionApiService.ROOT_POINT, data).pipe(map(res => ResaleTransactionModel.fromJson(res)));
  }

  update(id: number, data: ResaleTransactionUpdateDto) {
    return this._apiService.patch(ResaleTransactionApiService.ROOT_POINT + '/' + id, data, { excludeFields: [''] });
  }

  updateSpecialFields(id: number, data: ResaleTransactionUpdateSpecialFieldsDto) {
    return this._apiService.patch(ResaleTransactionApiService.UPDATE_SAVE_SPECIAL_POINT, Object.assign({ id }, data), { excludeFields: [''] });
  }

  updatePayment(id: number, data: ResaleTransactionUpdatePaymentDto) {
    return this._apiService.patch(ResaleTransactionApiService.UPDATE_PAYMENT_POINT, Object.assign({ id }, data), { excludeFields: [''] });
  }

  delete(id: number) {
    return this._apiService.delete(ResaleTransactionApiService.ROOT_POINT + '/' + id);
  }

  createSaveDraft(data: ResaleTransactionDto | ResaleTransactionClonedDto) {
    return this._apiService.post(ResaleTransactionApiService.SAVE_DRAFT_POINT, data).pipe(map(res => ResaleTransactionModel.fromJson(res)));
  }

  updateSaveDraft(id: number, data: ResaleTransactionUpdateDto) {
    return this._apiService.patch(ResaleTransactionApiService.UPDATE_SAVE_DRAFT_POINT, Object.assign({ id }, data));
  }

  searchBillings(query = {}, option?: IApiOption) {
    return this._apiService.get(ResaleTransactionApiService.BILLINGS_POINT, query, option).pipe(map(data => new PaginationAdapter(ResaleBillingModel, data)));
  }

  getBillingsByResaleId(resaleId: number, query = {}, option?: IApiOption) {
    return this._apiService.get(ResaleTransactionApiService.BILLINGS_POINT + '/' + resaleId, query, option).pipe(map(item => ResaleBillingModel.fromJson(item)));
  }

  createBilling(data: ResaleBillingDto) {
    return this._apiService.post(ResaleTransactionApiService.BILLINGS_POINT, data).pipe(map(res => ResaleBillingModel.fromJson(res)));
  }

  updateBilling(id: number, data: ResaleBillingUpdateDto) {
    return this._apiService.patch(ResaleTransactionApiService.BILLINGS_POINT + '/' + id, data, { excludeFields: [''] });
  }

  searchDocuments(query = {}, option?: IApiOption) {
    return this._apiService
      .get(ResaleTransactionApiService.DOCUMENTS_POINT, query, option)
      .pipe(map(data => merge(new PaginationAdapter(ResaleDocumentModel, data), data?.additionals)));
  }

  getDocumentsByResaleId(resaleId: number, query = {}, option?: IApiOption) {
    return this._apiService.get(ResaleTransactionApiService.DOCUMENTS_POINT + '/' + resaleId, query, option).pipe(map(item => ResaleDocumentModel.fromJson(item)));
  }

  setUpDocuments(data: ResaleDocumentSetUpDto) {
    return this._apiService.post(ResaleTransactionApiService.DOCUMENTS_SETUP_POINT, data);
  }

  setUpDocsForPayment(data: ResaleDocumentSetUpDto) {
    return this._apiService.post(ResaleTransactionApiService.DOCUMENTS_SETUP_PAYMENT_POINT, data);
  }

  submit(data: { id: number; data: object; metadata: object, relatedAgent?: object }) {
    return this._apiService.post(ResaleTransactionApiService.SUBMIT_POINT, data);
  }

  rework(id: number, reason = '') {
    return this._apiService.post(ResaleTransactionApiService.REWORK_POINT, { id, data: { reason } });
  }

  confirm(id: number, reason = '') {
    return this._apiService.post(ResaleTransactionApiService.CONFIRM_POINT, { id, data: { reason } });
  }

  completePcDocs(id: number) {
    return this._apiService.patch(ResaleTransactionApiService.COMPLETE_PC_DOC_POINT, { id });
  }

  reworkMultiple(resales: { id: number; data: { reason: string } }[]) {
    return this._apiService.post(ResaleTransactionApiService.REWORK_MULTIPLE_POINT, { resales });
  }

  reworkMultipleSubmission(resales: { id: number; data: { reason: string } }[]) {
    return this._apiService.post(ResaleTransactionApiService.SUBMISSION_REWORK_MULTIPLE_POINT, { resales });
  }

  reworkMultipleVerification(resales: { id: number; data: { reason: string } }[]) {
    return this._apiService.post(ResaleTransactionApiService.VERIFICATION_REWORK_MULTIPLE_POINT, { resales });
  }

  reworkMultipleInvoiceInterface(resales: { id: number; data: { reason: string } }[]) {
    return this._apiService.post(ResaleTransactionApiService.INVOICE_INTERFACE_REWORK_MULTIPLE_POINT, { resales });
  }


  confirmMultiple(resales: { id: number; data: { reason?: string }; metadata: object }[]) {
    return this._apiService.post(ResaleTransactionApiService.CONFIRM_MULTIPLE_POINT, { resales });
  }

  confirmMultipleSubmission(resales: { id: number; data: { reason?: string }; metadata: object }[]) {
    return this._apiService.post(ResaleTransactionApiService.SUBMISSION_CONFIRM_MULTIPLE_POINT, { resales });
  }

  confirmMultipleVerification(resales: { id: number; data: { reason?: string }; metadata: object }[]) {
    return this._apiService.post(ResaleTransactionApiService.VERIFICATION_CONFIRM_MULTIPLE_POINT, { resales });
  }

  confirmMultipleInvoiceInterface(resales: { id: number; data: { reason?: string }; metadata: object }[]) {
    return this._apiService.post(ResaleTransactionApiService.INVOICE_INTERFACE_CONFIRM_MULTIPLE_POINT, { resales });
  }

  withDrawMultiple(resales: { id: number; data: { reason?: string } }[]) {
    return this._apiService.post(ResaleTransactionApiService.WITHDRAW_MULTIPLE_POINT, { resales });
  }

  searchStatusHistories(query = {}, option?: IApiOption) {
    return this._apiService
      .get(ResaleTransactionApiService.STATUS_HISTORIES_POINT, query, option)
      .pipe(map(data => new PaginationAdapter(ResaleStatusHistoryModel, data)));
  }

  createFullData(data: ResaleTransactionModel, isCloned = false) {
    const dataJson = ResaleTransactionModel.toJson(data) as IResaleTransaction;
    const normalPayload = ResaleTransactionDto.fromJson(dataJson);
    const clonedPayload = ResaleTransactionClonedDto.fromJson(dataJson);
    const payload = isCloned ? clonedPayload : normalPayload;

    if (dataJson.status === EResaleStatus.draft) {
      return this.createSaveDraft(payload).pipe(
        switchMap(({ id: resaleId }) => {
          const { documents } = dataJson;
          const updatePayload = ResaleTransactionUpdateDto.fromJson({
            group: 'full',
            data: payload,
          });
          const documentsPayload = ResaleDocumentSetUpDto.fromJson({ isOverride: true, resaleId, data: documents });
          return forkJoin([documents.length ? this.setUpDocuments(documentsPayload) : of(true)]).pipe(map(() => resaleId));
        }),
        takeLast(1)
      );
    } else {
      return this.create(payload).pipe(
        switchMap(({ id: resaleId }) => {
          const { documents } = dataJson;
          const updatePayload = ResaleTransactionUpdateDto.fromJson({
            group: 'full',
            data: payload,
          });
          const documentsPayload = ResaleDocumentSetUpDto.fromJson({ isOverride: true, resaleId, data: documents });
          return forkJoin([documents.length ? this.setUpDocuments(documentsPayload) : of(true)]).pipe(map(() => resaleId));
        }),
        takeLast(1)
      );
    }
  }

  updateFullData(data: ResaleTransactionModel) {
    const dataJson = ResaleTransactionModel.toJson(data) as IResaleTransaction;
    const payload = ResaleTransactionUpdateDto.fromJson({
      group: 'full',
      data: dataJson,
    });
    const { id: resaleId, documents } = dataJson;
    const documentsPayload = ResaleDocumentSetUpDto.fromJson({ isOverride: true, resaleId, data: documents });

    if (dataJson.status === EResaleStatus.draft) {
      //return forkJoin([this.updateSaveDraft(resaleId, payload), this.setUpDocuments(documentsPayload)]).pipe(map(() => resaleId));
      return this.updateSaveDraft(resaleId, payload).pipe(switchMap((res) => {
        return this.setUpDocuments(documentsPayload).pipe(map(() => resaleId))
      }))

    } else {
      // update resale not is the first submit
      if (payload?.data?.metadata?.isFirstTimeSubmitted) {
        payload.data.metadata.isFirstTimeSubmitted = false;
      }
      return this.update(resaleId, payload).pipe(switchMap((res) => {
        return this.setUpDocuments(documentsPayload).pipe(map(() => resaleId))
      }))
    }
  }

  updateSubmitFullData(data: ResaleTransactionModel) {
    const dataJson = ResaleTransactionModel.toJson(data) as IResaleTransaction;
    const payload = ResaleTransactionUpdateDto.fromJson({
      group: 'full',
      data: dataJson,
    });
    // update resale not is the first submit
    if (payload?.data?.metadata?.isFirstTimeSubmitted) {
      payload.data.metadata.isFirstTimeSubmitted = false;
    }
    const { id: resaleId, documents } = dataJson;
    const documentsPayload = ResaleDocumentSetUpDto.fromJson({ isOverride: true, resaleId, data: documents });
    //return forkJoin([this.update(resaleId, payload), this.setUpDocuments(documentsPayload)]).pipe(map(() => resaleId));
    return this.update(resaleId, payload).pipe(switchMap((res) => {
      return this.setUpDocuments(documentsPayload).pipe(map(() => resaleId))
    }))
  }

  updateSaveSpecialFields(data: ResaleTransactionModel) {
    const dataJson = ResaleTransactionModel.toJson(data) as IResaleTransaction;
    const payload = ResaleTransactionUpdateSpecialFieldsDto.fromJson(dataJson);
    const { id: resaleId } = dataJson;
    return this.updateSpecialFields(resaleId, payload).pipe(map(() => resaleId));
  }

  searchEngine(query = {}, option?: IApiOption): Observable<PaginationAdapter<ResaleSearchEngineModel>> {
    return this._apiService
      .get(ResaleTransactionApiService.SEARCH_ENGINE_POINT, query, option)
      .pipe(map(data => new PaginationAdapter(ResaleSearchEngineModel, data)));
  }

  searchEngineFinance(query = {}, option?: IApiOption): Observable<PaginationAdapter<ResaleSearchEngineModel>> {
    return this._apiService
      .get(ResaleTransactionApiService.SEARCH_ENGINE_FINANCE_POINT, query, option)
      .pipe(map(data => new PaginationAdapter(ResaleSearchEngineModel, data)));
  }

  preview(data: ResaleTransactionPreviewDto): Observable<IResaleTransaction> {
    return this._apiService.post(ResaleTransactionApiService.PREVIEW_POINT, data);
  }

  pcDocs(resales: { id: number; data: object; metadata: { isPC: boolean } }[]) {
    return this._apiService.post(ResaleTransactionApiService.PC_DOCS_POINT, { resales });
  }

  skipEmail(id: number, skipEmail: boolean) {
    return this._apiService.patch(ResaleTransactionApiService.ROOT_POINT + '/' + id, { group: 'skipEmail', data: { skipEmail } }, { excludeFields: [''] });
  }

  updateRemark(id: number, remarks: string) {
    const payload = ResaleTransactionUpdateRemarkDto.fromJson({ group: 'remark', data: { remarks } }, { exposeUnsetFields: false });
    return this._apiService.patch(ResaleTransactionApiService.ROOT_POINT + '/' + id, payload);
  }

  updateRemarkInternal(id: number, remarksInternal: string) {
    const payload = ResaleTransactionUpdateRemarkDto.fromJson({ group: 'remark', data: { remarksInternal } }, { exposeUnsetFields: false });
    return this._apiService.patch(ResaleTransactionApiService.ROOT_POINT + '/' + id, payload);
  }

  updateRemarksInSearchEngine(id: number, data: ResaleTransactionRemarkDto) {
    return this._apiService.patch(ResaleTransactionApiService.ROOT_POINT + '/' + id + '/remarks', data);
  }

  downloadSearchEngine(body = {}, option?: IApiOption): Observable<any> {
    return this._apiService.post(ResaleTransactionApiService.SEARCH_ENGINE_DOWNLOAD, body, {
      pretreatmentResponse: false,
      requestOptions: { responseType: 'arraybuffer' },
      removeEmpty: {
        enable: true,
      },
    });
  }
  downloadSearchEngineFinance(body = {}, option?: IApiOption): Observable<any> {
    return this._apiService.post(ResaleTransactionApiService.SEARCH_ENGINE_FINANCE_DOWNLOAD, body, {
      pretreatmentResponse: false,
      requestOptions: { responseType: 'arraybuffer' },
      removeEmpty: {
        enable: true,
      },
    });
  }

  checkDuplicate(data: ResaleTransactionCheckDuplicateDto) {
    return this._apiService.post(ResaleTransactionApiService.ROOT_POINT + '/check-duplicated', data);
  }

  printSammaryReport(id: number, query = {}): Observable<TypedArray> {
    return this._apiService.get(ResaleTransactionApiService.ROOT_POINT + '/' + id + '/summary-report', query, {
      pretreatmentResponse: false,
      requestOptions: { responseType: 'arraybuffer' },
    });
  }

  getJob(data: Record<string, any>): Observable<IResaleJobRes> {
    return this._apiService.post(ResaleTransactionApiService.SEARCH_ENGINE_RESALE_JOB_DOWNLOAD, data, {
      removeEmpty: {
        enable: true,
      },
    })
  }
  getJobStatus(jobId: string): Observable<IResaleJobStatusRes> {
    return this._apiService.get(ResaleTransactionApiService.SEARCH_ENGINE_JOB_STATUS + `/${jobId}`)
  }
  getJobLink(jobId: string): Observable<string> {
    const url = this._apiService.createAPIURL(`${ResaleTransactionApiService.SEARCH_ENGINE_JOB_DOWNLOAD}/${jobId}`, {})
    return of(url)
  }

}
