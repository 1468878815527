import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExceljsQuestionComponent } from './exceljs-question.component';
import { ExceljsMediaWatcherResolver } from '../exceljs-media-watcher.resolver';

const routes: Routes = [
  {
    path: '',
    component: ExceljsQuestionComponent,
    resolve: { matchingAliases: ExceljsMediaWatcherResolver },
    providers: [
      ExceljsMediaWatcherResolver
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ExceljsQuestionRoutingModule { }
