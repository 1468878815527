import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AssetCategoryModel, YearsEndPostingModel } from '@shared/data-access/models';
import { filter, Observable, take } from 'rxjs';
import { AssetCategoryAction, YearsEndPostingAction } from '../actions';
import { AssetCategoryReducer, YearsEndPostingReducer } from '../reducers';
import { AssetCategorySelector, YearsEndPostingSelector } from '../selectors';

@Injectable()
export class YearsEndPostingFacade {
  selectedItem$ = this._store.select(YearsEndPostingSelector.selectYearsEndPosting);
  constructor(private _store: Store<YearsEndPostingReducer.YearsEndPostingState>) { }

  /**
   * Get @AssetCategoryModel by id
   */
  getById(id: number): Observable<YearsEndPostingModel | null> {
    this._store.dispatch(YearsEndPostingAction.loadItem({ id }));
    return this.selectedItem$.pipe(
      filter(val => !!val && val.id === id),
      take(1)
    ) as Observable<YearsEndPostingModel | null>;
  }
}
