import { PaginationAdapter } from '@red/data-access';
import { createAction, props } from '@ngrx/store';
import { YearsEndPostingModel } from '@shared/data-access/models';
import { IQuerySearch } from '@shared/data-access/interfaces';
// items
export const loadItems = createAction('[YearsEndPosting] Load items', props<{ filters: IQuerySearch }>());
export const loadItemsSuccess = createAction('[YearsEndPosting] Load items success', props<{ data: PaginationAdapter<YearsEndPostingModel> }>());

// item
export const loadItem = createAction('[YearsEndPosting] Load item', props<{ id: number }>());
export const loadItemSuccess = createAction('[YearsEndPosting] Load item success', props<{ item: YearsEndPostingModel }>());
