import { BaseModel } from '@red/data-access';
import { Expose } from 'class-transformer';
import { IAssetCategory, IYearsEndPosting } from '../interfaces';
import { AssetCategoryAccountType } from '../types';
import { UpdatedByModel } from './updated-by.model';
import { LedgerAccountModel } from './ledger-account.model';


export class YearsEndClosingModel extends BaseModel {
  @Expose()
  account!: LedgerAccountModel;

  @Expose()
  description!: string;

  @Expose()
  accountId!: number;

  @Expose()
  accountPeriodId!: number;

  @Expose()
  businessUnitId!: number;

  @Expose()
  createdAt!: string;

  @Expose()
  createdBy?: string;

  @Expose()
  deletedAt?: string;

  @Expose()
  deletedBy?: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  updatedBy?: UpdatedByModel;

  @Expose()
  postedDate!: string;

  @Expose()
  date!: string;

  @Expose()
  debit!: number;

  @Expose()
  credit!: number;
}

export class YearsEndPostingModel extends BaseModel implements IYearsEndPosting {
  @Expose()
  id!: number;

  @Expose()
  businessUnitId!: number;

  // Time stamp
  @Expose()
  createdAt!: string;

  @Expose()
  createdBy?: string;

  @Expose()
  deletedAt?: string;

  @Expose()
  deletedBy?: string;

  @Expose()
  updatedAt!: string;

  @Expose()
  updatedBy?: UpdatedByModel;

  @Expose()
  endDate!: string;

  @Expose()
  startDate!: string;

  @Expose()
  type!: string;

  @Expose()
  version!: number;

  @Expose()
  yearEndClosing!: YearsEndClosingModel[];
}
