import { createFeature, createReducer, on } from '@ngrx/store';
import { YearsEndPostingModel } from '@shared/data-access/models';
import { YearsEndPostingAction } from '../actions';

export const featureName = 'yearsEndPosting';

export interface YearsEndPostingState {
  item: YearsEndPostingModel | null;
}
export const initialState: YearsEndPostingState = {
  item: null,
};
export const yearsEndPostingFeature = createFeature({
  name: featureName,
  reducer: createReducer(
    initialState,
    on(YearsEndPostingAction.loadItemSuccess, (state, prop: { item: YearsEndPostingModel }) => ({ ...state, item: prop.item }))
  ),
});

export const {
  name, // feature name
  reducer, // feature reducer
} = yearsEndPostingFeature;
