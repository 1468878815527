import { UpdatedByModel } from '../models';
import { AssetCategoryAccountType } from '../types/fixed-asset.type';
import { ITimestamp } from './timestamp.interface';
export interface IYearsEndPosting {
  id?: number;
  businessUnitId: number;
  // Time stamp
  createdAt?: string;
  createdBy?: string;
  deletedAt?: string;
  deletedBy?: string;
  updatedAt?: string;
  updatedBy?: UpdatedByModel;
  endDate: string;
  startDate: string;
  type: string;
  version: number;
}
