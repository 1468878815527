import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';
import { BlankFormBatchSearchEngineAction } from '../actions';
import { BlankFormBatchSearchEngineSelector } from '../selectors';
import { BatchingApprovalApiService } from '@cms/apis';

@Injectable()
export class BlankFormBatchSearchEngineFacade {
  selectedItem$ = this._store.select(BlankFormBatchSearchEngineSelector.selectItem);

  constructor(private _store: Store, private _apiService: BatchingApprovalApiService) { }

  /**
   * Get @ProjectReportModel by id
   */
  getById(id: number) {
    return this._apiService.searchEngineBlankFormBatchById(id).pipe(
      tap(res => {
        this._store.dispatch(BlankFormBatchSearchEngineAction.setItemDetail({ item: res }));
      })
    );
  }
}
